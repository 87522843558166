



















import {
  defineComponent,
  useRoute,
  computed
} from '@nuxtjs/composition-api';
import CheckoutCart from 'components/checkout/Aside/CheckoutCart.vue';
import { ValidationObserver } from 'vee-validate';

export default defineComponent({
  name: 'Checkout',
  layout: 'checkout',
  components: {
    CheckoutCart,
    ValidationObserver
  },
  setup () {
    const route = useRoute();
    const isThankYou = computed(() => route.value.name.startsWith('checkout-thank-you'))
    const isOrder = computed(() => route.value.name.startsWith('checkout-order'))

    return {
      isOrder,
      isThankYou
    }
  }
})
