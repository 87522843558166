













































































































import {
  defineComponent,
  computed,
  ref,
  useContext,
  useRouter
} from '@nuxtjs/composition-api';
import { useCart } from '@wemade-vsf/customer';
import { useCartView , useMakeOrder} from '@wemade-vsf/checkout';
import { SfHeading, SfButton, SfLoader, SfPrice } from '@storefront-ui/vue';
import CartItem from 'components/checkout/Aside/CartItem.vue';
import { useCustomerPricing } from '@wemade-vsf/catalog/lib/runtime/product/composables/useCustomerPricing';
import { useEvent } from '@wemade-vsf/composables';
import type { PlaceOrderOutput } from '@wemade-vsf/magento-api';
import CartItemAdditionalInformation from 'components/checkout/Cart/CartItemAdditionalInformation.vue';

export default defineComponent({
  name: 'CheckoutCart',
  components: {
    SfHeading,
    CartItem,
    SfButton,
    SfLoader,
    SfPrice,
    CartItemAdditionalInformation
  },
  props: {
    valid: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    const { app } = useContext();
    const router = useRouter();
    const { cart, setCart, load: loadCart } = useCart();
    const cartView = useCartView();
    const { make, loading: isPlacingOrder } = useMakeOrder();
    const order = ref<PlaceOrderOutput|null>(null)
    const shippingTotal = computed(() => {
      if (cart.value) {
        return cartView.cartGetters.getShippingPrice(cart.value)
      }
      return 0
    })
    const taxTotal = computed(() => {
      if (cart.value && cart.value.prices?.applied_taxes?.length) {
        return cart.value.prices.applied_taxes.reduce((acc, item) => {
          return item.amount?.value ? acc + item.amount.value : acc
        }, 0)
      }
      return 0
    })
    const subtotalExTax = computed(() => {
      return cart.value?.prices?.subtotal_excluding_tax?.value || null
    })

    const { displayPricesIncludingTax: isPricesIncTax } = useCustomerPricing();

    const canCheckout = computed(() => {
      return props.valid && cart.value?.billing_address?.street
        && cart.value?.shipping_addresses?.[0]?.street
        && cart.value?.selected_payment_method?.code
        && cart.value?.shipping_addresses?.[0]?.selected_shipping_method?.method_code
    })

    async function placeOrder() {
      order.value = await make(cart.value.id ?? '');
      if (order.value?.order?.order_number) {
        useEvent('order:placed:fromCart', { orderNumber: order.value.order.order_number, cart: cart.value })
      }
      setCart(null)
      app.$vsf.$magento.config.state.removeCartId();
      await loadCart({ realCart: true });
      
      router.push(app.localeRoute({
        name: 'checkout-thank-you',
        query: {
          order: order.value.order.order_number
        }
      }))
    }

    return {
      cart,
      ...cartView,
      isPricesIncTax,
      shippingTotal,
      taxTotal,
      subtotalExTax,
      canCheckout,
      isPlacingOrder,
      placeOrder
    }
  }
})
