





















































































import {
  defineComponent,
  PropType,
  ref
} from '@nuxtjs/composition-api';
import type { SelectedConfigurableOption, SelectedCustomizableOption } from '@wemade-vsf/magento-api';
import { SfProperty, SfPrice, SfButton, SfQuantitySelector } from '@storefront-ui/vue';


export default defineComponent({
  name: 'CartItem',
  props: {
    uid: {
      type: String,
      default: ''
    },
    image: {
      type: String,
      default: ''
    },
    imageWidth: {
      type: Number,
      default: 100
    },
    imageHeight: {
      type: Number,
      default: 100
    },
    attributeOptions: {
      type: Array as PropType<Array<SelectedConfigurableOption>>,
      default: () => []
    },
    customOptions: {
      type: Array as PropType<Array<SelectedCustomizableOption>>,
      default: () => []
    },
    title: {
      type: String,
      default: ''
    },
    link: {
      type: String,
      default: ''
    },
    qty: {
      type: Number,
      default: 1
    },
    qtyUnit: {
      type: String,
      default: ''
    },
    regularPrice: {
      type: String,
      default: ''
    },
    specialPrice: {
      type: String,
      default: ''
    },
    showActions: {
      type: Boolean,
      default: false
    }
  },
  components: {
    SfProperty,
    SfPrice,
    SfButton,
    SfQuantitySelector
  },
  setup (props, { emit }) {
    const isEditMode = ref(false)
    const newQty = ref(props.qty)
    const toggleEditMode = () => {
      if (!isEditMode.value) {
        newQty.value = props.qty;
      }
      isEditMode.value = !isEditMode.value
    }

    const updateItem = () => {
      isEditMode.value = false
      emit('update-item', newQty.value)
    }

    return {
      isEditMode,
      newQty,
      toggleEditMode,
      updateItem
    }
  }
})
